import { mapActions } from 'vuex';

//---helpers
import { BLOG_NEWS_TYPE } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import IconClock from '@/components/icons/IconClock.vue';
import ContactPage from '@/modules/pages/contacts';
import ContentBlock from '@/components/custom/ContentBlock/index.vue';

export default {
  name: 'Article',
  mixins: [dynamicHead],
  components: { ContentBlock, ContactPage, IconClock, BreadcrumbsSimple },
  props: {},
  data() {
    return {
      articleType: BLOG_NEWS_TYPE.INTERESTING,
      articleData: null
    };
  },
  created() {
    console.log('ARTICLE', this.$route);
    let { name } = this.$route;
    if (name.indexOf(BLOG_NEWS_TYPE.NEWS) > -1) {
      this.articleType = BLOG_NEWS_TYPE.NEWS;
    }
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    breadcrumbs() {
      return this.articleType === BLOG_NEWS_TYPE.NEWS
        ? [{ title: this.$t('page.news.title'), route: { name: 'news' } }]
        : [{ title: this.$t('page.interesting.title'), route: { name: 'interesting' } }];
    }
  },
  methods: {
    ...mapActions({
      getBlogNewsArticle: 'blog/GET_BLOG_NEWS_ARTICLE',
      getBlogInterestingArticle: 'blog/GET_BLOG_INTERESTING_ARTICLE'
    }),
    async _loadContent() {
      globalLoader(true);
      let { slug } = this.$route.params;
      let result = null;
      if (this.articleType === BLOG_NEWS_TYPE.NEWS) {
        result = await this.getBlogNewsArticle(slug);
      } else if (this.articleType === BLOG_NEWS_TYPE.INTERESTING) {
        result = await this.getBlogInterestingArticle(slug);
      }

      this.articleData = result.data;
      this._setMetaParams();
      this.setMeta();
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, title, metaDescription, image } = this.articleData;
      if (title || metaTitle)
        this.metaParams.title = `${metaTitle ? metaTitle : title}${this.$t('seo.metaTitleGeneral')}`;

      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
    }
  }
};
